// External Dependencies
import React from 'react';

// Internal Dependencies
import HeaderGeneric from '../components/HeaderGeneric';
import Layout from '../components/layout';
import Meta from '../components/Meta';
import RaffleWinners from '../components/RaffleWinners';

// Local Variables
const winners = [
  {
    day: 'Thursday',
    directorName: 'Christi Speer',
    location: 'Harleton, TX',
    school: 'Harleton ISD Band',
  },
  {
    day: 'Friday',
    directorName: 'Hannah Lansdell',
    location: 'Duncanville, TX',
    school: 'Reed Middle School Band',
  },
  {
    day: 'Saturday',
    directorName: 'Sydney Cohen',
    location: 'Commerce, TX',
    school: 'Commerce High School Band',
  },
];

// Component definition
const SwMusic2021RaffleEntry = () => (
  <Layout>
    <Meta title="Raffle Entry" />
    <HeaderGeneric
      title="Congratulations to our 2021 Summer Convention raffle winners!"
    />

    <div>
      <section id="content" className="main">
        <RaffleWinners winners={winners} />
      </section>
    </div>

  </Layout>
);

export default SwMusic2021RaffleEntry;
