// External Dependencies
import PropTypes from 'prop-types';
import React from 'react';

// Local Dependencies
import RaffleWinner from './RaffleWinner';

// Local Variables
const propTypes = {
  winners: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string.isRequired,
      directorName: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      school: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

// Component Definition
const RaffleWinners = ({ winners }) => (
  <ul className="raffle-winners">
    {winners.map((winner) => (
      <li key={winner.day}>
        <RaffleWinner winner={winner} />
      </li>
    ))}
  </ul>
);

RaffleWinners.propTypes = propTypes;

export default RaffleWinners;
