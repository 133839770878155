// External Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Local Variables
const propTypes = {
  winner: PropTypes.shape({
    day: PropTypes.string.isRequired,
    directorName: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    school: PropTypes.string.isRequired,
  }).isRequired,
};

const RaffleWinner = ({ winner }) => (
  <div className="card align-center">
    <p className="legal">{winner.day}</p>

    <h2>{winner.school}</h2>

    <h4>{winner.location}</h4>

    <h3>{winner.directorName}</h3>
  </div>
);

RaffleWinner.propTypes = propTypes;

export default RaffleWinner;
